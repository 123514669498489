import React from "react"
import Layout from "../components/utils/layout"
import MeetTheTeamC from "../components/headerAndFooter/meetTheTeam"
import SEO from "../components/utils/seo"
import officeBg from "../images/bgs/bg2.svg"
import { Container } from "react-bootstrap"
import dots from "../images/bgs/dots.svg"


const MeetTheTeam = () => (
  <Layout image={dots}>
    <SEO title="Meet the Team" />
    <Container
      style={{
        backgroundImage: `url(${officeBg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      fluid={true}
    >
      <MeetTheTeamC />
    </Container>
  </Layout>
)

export default MeetTheTeam
