import React from "react" // import react
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Figure from "react-bootstrap/Figure"
import Row from "react-bootstrap/Row"
import Titleline from "../../components/utils/Titleline"
import Arnav from "../../images/Arnav.jpg"
import Arnuv from "../../images/Arnuv.jpg"
import Jai from "../../images/Jai.jpg"
import Tanvi from "../../images/Tanvi.png"
import Anika from "../../images/Anika.jpg"
import Pranav from "../../images/Pranav.jpg"
import Isha from "../../images/Isha.jpg"
import Mano from "../../images/Mano.png"
import Mariam from "../../images/mariam-mortada.png"
import Emily from "../../images/emily-sansano.jpg"
import Anushka from "../../images/anushka-wagle.jpeg"
import Abhinav from "../../images/abhinav-guntaka.png"
import Steven from "../../images/steven-dohm.jpg"
import "../../styles/About.css"

function MeetTheTeamC() {
  return (
    <>
      <br></br>
      <Titleline
        title="Meet the Team"
        description={
          <i>
            {" "}
            RemoteTA's leadership team is comprised of individuals who are
            passionate about shaping their communities with technology.
          </i>
        }
      ></Titleline>
      <br></br>
      <br></br>
      <Container
        className="justify-content-center"
        style={{ paddingLeft: "45px", paddingRight: "45px" }}
      >
        <Row
          className="justify-content-center"
          style={{ marginTop: "-10px", marginBottom: "40px" }}
        >
          <h2>Executive Leadership</h2>
        </Row>
        <Row className="justify-content-center">
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Arnuv} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Arnuv Tandon
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Founder and President</i>
              </span>
              <span className="description center">
                Hello! My name is Arnuv Tandon and I am a junior at Homestead
                High School. I am passionate about driving change in the local
                community and using my programming knowledge to do so. Starting
                RemoteTA from scratch and see it slowly develop into a viable,
                impactful platform has been one of the more rewarding
                experiences thus far in my life. If you have any questions
                regarding RemoteTA, please feel free to contact me!
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Arnav} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Arnav Kulkarni
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Chief Technology Officer</i>
              </span>
              <span className="description center">
                Hi! My name is Arnav Kulkarni and I am a junior at Homestead
                High School. I am an avid programmer/developer, and love using
                code to shape the world around me. I cannot wait to see the
                large and widepsread impact RemoteTA could potentially bring to
                countless teachers and students nationwide.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Jai} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Jai Bhatia
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Vice President of Outreach</i>
              </span>
              <span className="description center">
                Hello! My name is Jai Bhatia and I am in the class of 2023 at
                Fremont High School. I tend to take up positions in leadership
                and charity/activism. For me, joining RemoteTA blended my
                passion for leadership and charity into an exciting mixture that
                can hopefully impact the world!
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Pranav} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Pranav Kulkarni
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Vice President of Public Relations</i>
              </span>
              <span className="description center">
                Hi! My name is Pranav and I am a junior at Homestead High
                School. I love participating in student-run organizations like
                RemoteTA and enjoy sharing fun work experiences with my peers.
                RemoteTA has been a great place to make real change in my local
                communities and meet new people.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Tanvi} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Tanvi Wadhawan
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Senior Engineer</i>
              </span>
              <span className="description center">
                Hi! My name is Tanvi and I attend Fremont High School. I love
                coding and giving back to my community through volunteer work.
                RemoteTA is an amazing way for me to combine my love for both.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Anika} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Anika Khandavalli
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Senior Engineer</i>
              </span>
              <span className="description center">
                Hey! My name is Anika, and I go to Fremont High School. I am
                passionate about helping our community in any way, from
                volunteering to protesting, and I love programming. Joining
                RemoteTA bridged my two interests and become a part of something
                that can impact so many people.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Isha} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Isha Singh
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Head of Communications</i>
              </span>
              <span className="description center">
                Hi, my name is Isha Singh and I'm a Sophomore at Fremont High
                School. I'm involved in a lot of student leadership, and I'm
                passionate about giving back to my community. As a student, I
                care a lot about helping teachers and educators as a return for
                all the incredibly hard work they put in. Working on RemoteTA
                has been an amazing way for me to use my skills and experience
                and collaborate with my fellow students in order to make a
                positive impact.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Mano} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Mano Tatapudi
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Head of Regional Affairs</i>
              </span>
              <span className="description center">
                Hi! My name is Mano Tatapudi. I am a sophomore at Fremont High
                School and the Regional Managers Head here at RemoteTA. I am
                passionate about giving back to my community and leadership so
                RemoteTA is the perfect opportunity to combine both and give
                back to teachers!
              </span>
            </Row>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          style={{ marginTop: "30px", marginBottom: "40px" }}
        >
          <h2>Regional Managers</h2>
        </Row>
        <Row className="justify-content-center">
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Mariam} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Mariam Mortada
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Regional Manager</i>
              </span>
              <span className="description center">
                Hey! I am Mariam Mortada, a Regional Manager at Remote TA and a
                senior at Fremont High School who is devoted to supporting
                teachers during this pandemic. I have previous outreach
                experience with another nonprofit organization and have had
                leadership roles at my school. These have equipped me with the
                necessary skills to form partnerships with schools and provide
                volunteering opportunities for other passionate students!
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Emily} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Emily Sansano
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Regional Manager</i>
              </span>
              <span className="description center">
                Hi my name is Emily Sansano, I am a Sophomore at Fremont High
                School. I am always looking for ways to create an impact in my
                community. I am looking forward to being able to help others as
                a regional manager of RemoteTA!
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Anushka} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Anushka Wagle
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Regional Manager</i>
              </span>
              <span className="description center">
                Hi, my name is Anushka and I am a senior at Fremont High School.
                Some things I am interested in include water polo, robotics, and
                biochemistry.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Abhinav} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Abhinav Guntaka
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Regional Manager</i>
              </span>
              <span className="description center">
                My name is Abhinav Guntaka and I am a sophomore at Fremont High
                School. I joined RemoteTA as a volunteer originally and found
                this whole process very interesting. I wanted to get the news
                out to more people, which is why I became a regional manager.
              </span>
            </Row>
          </Col>
          <Col xl={{ span: 5 }} style={{ marginBottom: "60px" }}>
            <Row className="justify-content-center">
              <Figure>
                <Figure.Image src={Steven} rounded />
              </Figure>
            </Row>
            <Row className="justify-content-center">
              <h3
                className="title"
                style={{ color: "#000", marginTop: "20px" }}
              >
                Steven Dohm
              </h3>
            </Row>
            <Row className="justify-content-center">
              <span
                className="description center"
                style={{ marginBottom: "12px", color: "#2c3e50" }}
              >
                <i>Regional Manager</i>
              </span>
              <span className="description center">
                Steven Dohm is a Student Technology Leader at Mentor High School
                in Mentor, OH. With being a technology leader, he assists
                students and faculty daily with issues from technology to
                digitizing worksheets. He is very highly recommended by his
                peers and other staff members in the building. He is both a very
                engaged student, and has over 400 community service hours in
                assisting non-profits.
              </span>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default MeetTheTeamC
